import {createReducer, on} from '@ngrx/store';
import {uploadAssistanceReporting} from '../action/assistance.action';
import {DisputeRequest} from '../../../../modules/profile/models/dispute.model';

export const assistanceReducer = createReducer(
    {},
    on(uploadAssistanceReporting.setAssistanceReporting, (state: DisputeRequest, {data}) => ({
        ...state,
            data
    })),
    on(uploadAssistanceReporting.resetAssistanceReporting, (_state: DisputeRequest, {}) => ({}))
);
